<template>
  <el-dialog
    :title="title"
    :visible.sync="open"
    width="80%"
    :before-close="close"
    destroy-on-close
  >
    <div class="padd15">
      <el-divider></el-divider>
      <div class="x-bc marB10">
        <el-button size="small">刷新</el-button>
        <el-input
          placeholder="请输入商品名称"
          style="width: 260px"
          size="small"
          v-model="queryParams.goodsName"
          class="input-with-select"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getList"
          ></el-button>
        </el-input>
      </div>
      <el-table
        :data="goodsList"
        style="width: 100%"
        ref="activityGoods"
        height="400px"
        @row-click="handleSelectRow"
        @selection-change="handleSelectionChange"
        row-key="keyId"
      >
        <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
        <el-table-column
          v-for="(item, index) in hander"
          :prop="item.value"
          :label="item.label"
          :key="index"
          align="center"
        >
        <template slot-scope="scope">
          <div v-if="item.value=='executeStatus'">
          {{scope.row.executeStatus==0?'未执行':scope.row.executeStatus==1?'执行中':'已结束'}}
          </div>
          <div v-else>
          {{scope.row[item.value]}}
          </div>
        </template>
        </el-table-column>
      </el-table>
      <div class="x-bc">
        <div class="x-f">已选择 {{ goodNum }} 个</div>
        <pagination
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" size="small" @click="selectGoodsSubmit"
        >确 定</el-button
      >
      <el-button size="small" @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { activityGoodList } from '@/api/O2OMall/config/template'

export default {
  name: 'groupBookingDialog',
  props: {
    open: {
      type: Boolean,
      request: true
    },
    type: {
      type: String // 0：普通商品 101：拼团商品 102：秒杀商品
    },
       // 已选数据
     multipleSelection:{
    type:Array,
    default:()=>[]
   }
  },
  watch: {
    type: {
      handler (newVal) {
        console.log('类型：', typeof newVal)
        if (newVal == '0') {
          this.title = '选择商品'
          this.hander = this.tableHander0
        } else if (newVal == '1') {
          this.title = '拼团商品'
          this.hander = this.tableHander1
        } else if (newVal == '2') {
          this.title = '秒杀商品'
          this.hander = this.tableHander1
        } else if (newVal == '3') {
          this.title = '砍价商品'
          this.hander = this.tableHander1
        }
      },
      immediate: true,
      deep: true
    },
    open: {
     async handler (val) {
        if (val) {
          await this.getList()
          this.goodNum = this.multipleSelection.length
          this.$nextTick(() => {
          this.multipleSelection.forEach(row => {
          this.$refs.activityGoods.toggleRowSelection(row, true);
          });
         });
        }
      }
    }
  },
  data () {
    return {
      title: '',
      goodNum: 0,
      hander: [],
      tableHander0: [{ label: '', value: '' }], // 普通商品表头
      tableHander1: [
        { label: '商品编码', value: 'goodsNo' },
        { label: '商品名称', value: 'goodsName' },
        { label: '活动状态', value: 'executeStatus' },
        { label: '活动价', value: 'activityPrice' },
        { label: '划线价', value: 'liningPrice' },
        { label: '活动开始时间', value: 'activityBeginTime' },
        { label: '活动结束时间', value: 'activityEndTime' }
      ], // 拼团商品表头
      goodsList: [], // 商品列表
      tableSelectedRows: [],
      total: 0, // 总数
      queryParams: {
        activityType: '',
        pageSize: 15,
        pageNum: 1,
        goodsName: ''
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    close () {
      this.$emit('close')
    },
    selectGoodsSubmit () {
      if ((this.type==1||this.type==2||this.type==3)&&this.tableSelectedRows.length>10)return this.$message.error('最多选择10个活动商品！')
      this.$emit('close', this.tableSelectedRows)
    },
    handleSelectRow (row) {
      this.$refs.activityGoods.toggleRowSelection(row)
    },
    handleSelectionChange (val) {
      console.log('选中-----：', val)
      this.tableSelectedRows = val
      this.goodNum = val.length
    },
    // 获取商品列表
    async getList () {
      if (this.type == 0) {
      } else if (this.type == 1) {
        this.queryParams.activityType = 101
      } else if (this.type == 2) {
        this.queryParams.activityType = 102
      } else if (this.type == 3) {
        this.queryParams.activityType = 103
      }
      activityGoodList(this.queryParams).then(res => {
        this.goodsList = res.rows.map(v=>({...v,keyId:v.activityId+v.goodsId+v.unitId}))
        this.total = res.total
      })
    }
  }
}
</script>

<style scoped></style>
