<template>
  <div class="padd15" style="overflow-y: auto">
    <div>
      <h1>{{ getHeadTitle || ''}} </h1>
      <div v-if="rightData && rightData.name == 'header'">
        <el-divider></el-divider>
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item label="页面名称" required>
            <el-input
              v-model="rightData.title"
              @change="templatehead"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <component :keys="`${new Date()}`" :data="rightData" :is="rightData.name" @setTemp="setTemp"/>
      <!-- <goods
        v-if="rightData && rightData.name == 'goods'"
        :data="rightData"
        @setTemp="setTemp"
      ></goods>
      <wenben
        v-else-if="rightData && rightData.name == 'wenben'"
        :data="rightData"
        @setTemp="setTemp"
      ></wenben>
      <fengefuzhu
        v-else-if="rightData && rightData.name == 'fengefuzhu'"
        :data="rightData"
        @setTemp="setTemp"
      ></fengefuzhu>
      <gonggao
        v-else-if="rightData && rightData.name == 'gonggao'"
        :data="rightData"
        @setTemp="setTemp"
      ></gonggao>
      <sousuo
        v-else-if="rightData && rightData.name == 'sousuo'"
        :data="rightData"
        @setTemp="setTemp"
      ></sousuo>
      <imageNav
        v-else-if="rightData && rightData.name == 'imageNav'"
        :data="rightData"
        @setTemp="setTemp"
      ></imageNav>
      <guanggao
        v-else-if="rightData && rightData.name == 'guanggao'"
        :data="rightData"
        @setTemp="setTemp"
      ></guanggao>
      <video-tmp
        v-else-if="rightData && rightData.name == 'videoTmp'"
        :data="rightData"
        @setTemp="setTemp"
      ></video-tmp>
      <zhangfen
        v-else-if="rightData && rightData.name == 'zhangfen'"
        :data="rightData"
        @setTemp="setTemp"
      ></zhangfen>
      <zichan
        v-else-if="rightData && rightData.name == 'zichan'"
        :data="rightData"
        @setTemp="setTemp"
      ></zichan>
      <coupon
        v-else-if="rightData && rightData.name == 'coupon'"
        :data="rightData"
        @setTemp="setTemp"
      ></coupon>
      <pintuan
        v-else-if="rightData && rightData.name == 'pintuan'"
        :data="rightData"
        @setTemp="setTemp"
      ></pintuan>
      <miaosha
        v-else-if="rightData && rightData.name == 'miaosha'"
        :data="rightData"
        @setTemp="setTemp"
      ></miaosha>
      <zhekou
        v-else-if="rightData && rightData.name == 'zhekou'"
        :data="rightData"
        @setTemp="setTemp"
      ></zhekou>
      <gexinghua
        v-else-if="rightData && rightData.name == 'gexinghua'"
        :data="rightData"
        @setTemp="setTemp"
      ></gexinghua>
      <shangpintu
        v-else-if="rightData && rightData.name == 'shangpintu'"
        :data="rightData"
        @setTemp="setTemp"
      />
      <shangpinxinxi
        v-else-if="rightData && rightData.name == 'shangpinxinxi'"
        :data="rightData"
        @setTemp="setTemp"
      />
      <shangpinguige
        v-else-if="rightData && rightData.name == 'shangpinguige'"
        :data="rightData"
        @setTemp="setTemp"
      />
      <shangpinpingjia
        v-else-if="rightData && rightData.name == 'shangpinpingjia'"
        :data="rightData"
        @setTemp="setTemp"
      />
      <shangpinxiangqing
        v-else-if="rightData && rightData.name == 'shangpinxiangqing'"
        :data="rightData"
        @setTemp="setTemp"
      />
      <dibudaohang
        v-else-if="rightData && rightData.name == 'dibudaohang'"
        :data="rightData"
        @setTemp="setTemp"
      />
      <fuwufangshi
        v-else-if="rightData && rightData.name == 'fuwufangshi'"
        :data="rightData"
        @setTemp="setTemp"
      />
      <shangpindaohang
        v-else-if="rightData && rightData.name == 'shangpindaohang'"
        :data="rightData"
        @setTemp="setTemp"
      />
      <biaotilan
        v-else-if="rightData && rightData.name == 'biaotilan'"
        :data="rightData"
        @setTemp="setTemp"
      />
      <huiyuanxinxi
        v-else-if="rightData && rightData.name == 'huiyuanxinxi'"
        :data="rightData"
        @setTemp="setTemp"
      />
      <liebiaodaohang
        v-else-if="rightData && rightData.name == 'liebiaodaohang'"
        :data="rightData"
        @setTemp="setTemp"
      />
      <tubiaozu
        v-else-if="rightData && rightData.name == 'tubiaozu'"
        :data="rightData"
        @setTemp="setTemp"
      />
      <anniuzu
        v-else-if="rightData && rightData.name == 'anniuzu'"
        :data="rightData"
        @setTemp="setTemp"
      />
      <Mofang
        v-else-if="rightData && rightData.name == 'mofang'"
        :data="rightData"
        @setTemp="setTemp"
      />
      <Kanjia
        v-else-if="rightData && rightData.name == 'kanjia'"
        :data="rightData"
        @setTemp="setTemp"
      />
      <Plusvip
        v-else-if="rightData && rightData.name == 'plusvip'"
        :data="rightData"
        @setTemp="setTemp"
      />
      <tanchuang
        v-else-if="rightData && rightData.name == 'tanchuang'"
        :data="rightData"
        @setTemp="setTemp"
      ></tanchuang>
      <Immersive
        v-else-if="rightData && rightData.name == 'immersive'"
        :data="rightData"
        @setTemp="setTemp"
      ></Immersive> -->
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import Navigation from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/navigation.vue'
import Goods from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/goods.vue' //控制商品样式组件
import Wenben from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/wenben.vue'
import Fengefuzhu from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/fengefuzhu.vue'
import Gonggao from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/gonggao.vue'
import Sousuo from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/sousuo.vue'
import ImageNav from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/imageNav.vue' //图文导航
import Guanggao from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/guanggao.vue'
import VideoTmp from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/videoTmp.vue'
import Zhangfen from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/zhangfen.vue'
import Zichan from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/zichan.vue'
import Coupon from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/coupon.vue'
import Pintuan from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/pintuan.vue'
import Miaosha from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/miaosha.vue'
import Zhekou from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/zhekou.vue'
import Gexinghua from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/gexinghua.vue'
//商品图
import Shangpintu from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/shangpintu.vue'
//商品信息
import Shangpinxinxi from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/shangpinxinxi.vue'
//商品规格
import Shangpinguige from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/shangpinguige.vue'
//商品评价
import Shangpinpingjia from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/shangpinpingjia.vue'
//商品详情
import Shangpinxiangqing from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/shangpinxiangqing.vue'
//底部导航
import Dibudaohang from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/dibudaohang.vue'
//服务方式
import Fuwufangshi from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/fuwufangshi.vue'
//商品导航
import Shangpindaohang from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/shangpindaohang.vue'
//标题栏
import Biaotilan from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/biaotilan.vue'
//会员信息
import Huiyuanxinxi from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/huiyuanxinxi.vue'
//列表导航
import Liebiaodaohang from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/liebiaodaohang.vue'
//图标组
import Tubiaozu from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/tubiaozu.vue'
//图标组
import Anniuzu from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/anniuzu.vue'
//
import Mofang from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/mofang.vue'
//砍价
import Kanjia from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/kanjia.vue'
//PLUS会员
import Plusvip from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/plusVip.vue'
//悬浮组件
import tanchuang from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/tanchuang.vue' //图文导航

import Immersive from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/immersive.vue'
import rubikcube from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/rubikcube.vue'
import switching from '@/views/O2OMall/config/template/decoratePage/components/rightTemplate/switching.vue'

export default {
  name: 'right-statue',
  components: {
    switching,
    rubikcube,
    Immersive,
    Mofang,
    Wenben,
    Fengefuzhu,
    Gonggao,
    Sousuo,
    ImageNav,
    Guanggao,
    Goods,
    VideoTmp,
    Zhangfen,
    Zichan,
    Coupon,
    Pintuan,
    Miaosha,
    Zhekou,
    Gexinghua,
    Shangpintu,
    Shangpinxinxi,
    Shangpinguige,
    Shangpinpingjia,
    Shangpinxiangqing,
    Dibudaohang,
    Fuwufangshi,
    Shangpindaohang,
    Biaotilan,
    Huiyuanxinxi,
    Liebiaodaohang,
    Tubiaozu,
    Anniuzu,
    Kanjia,
    Plusvip,
    tanchuang,
    Navigation
  },
  props: {
    // rightData: {
    //     type: Object,
    //     default: () => {
    //     }
    // }
  },
  data () {
    return {
      headline: '店铺主页',
      addImgDialog: false // 添加图片弹框
    }
  },
  computed: {
    ...Vuex.mapState({
      rightData: state => state.template.rightData
    }),
    getHeadTitle () {
      // console.log('name----', typeof this.rightData, this.$store.state.template.rightData)
      if (this.rightData == undefined || this.rightData == 'undefined') return
      console.log('name--2--', this.rightData)
      const componentNames = {
        mofang: '魔方',
        header: '顶部导航',
        wenben: '标题文本',
        fengefuzhu: '分割辅助',
        gonggao: '公告',
        sousuo: '商品搜索',
        imageNav: '图文导航',
        goods: '商品',
        videoTmp: '视频',
        zhangfen: '涨粉',
        zichan: '会员资产',
        coupon: '优惠券',
        pintuan: '拼团',
        miaosha: '秒杀',
        zhekou: '限时折扣',
        gexinghua: '个性化推荐',
        shangpintu: '商品图',
        shangpinxinxi: '商品信息',
        shangpinguige: '商品规格',
        shangpinpingjia: '商品评价',
        shangpinxiangqing: '商品详情',
        dibudaohang: '底部导航',
        fuwufangshi: '服务方式',
        shangpindaohang: '商品导航',
        biaotilan: '标题栏',
        huiyuanxinxi: '会员信息',
        plusvip: 'PLUS会员',
        liebiaodaohang: '列表导航',
        tubiaozu: '图标组',
        anniuzu: '按钮组',
        kanjia: '砍价',
        immersive: '沉浸式轮播图',
        guanggao: '广告图片',
        tanchuang: '悬浮',
        Kefu: '客服',
        switching: '门店切换',
        navigation: '底部导航栏',
      }
      return componentNames[this.rightData.name] || ''
    }
  },
  methods: {
    templatehead () {
      this.$emit('setTemphead', this.headline)
    },
    setTemp (data) {
      this.$emit('setTemp', data)
    },
    // 关闭弹框
    addImgDialoghandle () {
      this.addImgDialog = false
    },
    //  成功选择图片
    selectImgOk () {}
  }
}
</script>

<style lang="scss" scoped>
.imgSty {
  width: 20px;
  height: 20px;
}
</style>
