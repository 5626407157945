var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.open,
        width: "80%",
        "before-close": _vm.close,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.open = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "padd15" },
        [
          _c("el-divider"),
          _c(
            "div",
            { staticClass: "x-bc marB10" },
            [
              _c("el-button", { attrs: { size: "small" } }, [_vm._v("刷新")]),
              _c(
                "el-input",
                {
                  staticClass: "input-with-select",
                  staticStyle: { width: "260px" },
                  attrs: { placeholder: "请输入商品名称", size: "small" },
                  model: {
                    value: _vm.queryParams.goodsName,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "goodsName", $$v)
                    },
                    expression: "queryParams.goodsName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: { click: _vm.getList },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "activityGoods",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.goodsList,
                height: "400px",
                "row-key": "keyId",
              },
              on: {
                "row-click": _vm.handleSelectRow,
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "55",
                  "reserve-selection": true,
                },
              }),
              _vm._l(_vm.hander, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    prop: item.value,
                    label: item.label,
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            item.value == "executeStatus"
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.executeStatus == 0
                                          ? "未执行"
                                          : scope.row.executeStatus == 1
                                          ? "执行中"
                                          : "已结束"
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("div", [
                                  _vm._v(
                                    " " + _vm._s(scope.row[item.value]) + " "
                                  ),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "x-bc" },
            [
              _c("div", { staticClass: "x-f" }, [
                _vm._v("已选择 " + _vm._s(_vm.goodNum) + " 个"),
              ]),
              _c("pagination", {
                attrs: {
                  total: _vm.total,
                  page: _vm.queryParams.pageNum,
                  limit: _vm.queryParams.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.selectGoodsSubmit },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.close } },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }